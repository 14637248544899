<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一企一档列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">每期档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="名称" class="searchboxItem ci-full">
              <span class="itemLabel">名称:</span>
              <el-input
                v-model="phaesName"
                type="text"
                size="small"
                placeholder="请输入名称"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="enterpriseName"
                type="text"
                size="small"
                placeholder="请输入企业名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handlAdd()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="名称"
                align="left"
                prop="phaesName"
                show-overflow-tooltip
              />
              <el-table-column
                label="日期"
                align="left"
                prop="selectDate"
                show-overflow-tooltip
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
              />
              <el-table-column
                label="企业名称"
                align="left"
                prop="enterpriseName"
                show-overflow-tooltip
              />
              <el-table-column label="操作" align="center" width="350px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlEdit(scope.row, 'edit')"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlCatalogueOfArchives(scope.row)"
                    >档案目录</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlDelete(scope.row.phaseId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      @close="close"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="名称" prop="phaesName">
            <el-input v-model="ruleForm.phaesName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="选择时间" prop="date">
            <el-date-picker
              size="small"
              v-model="ruleForm.date"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="选择班级" prop="projectId">
            <el-select
              size="small"
              style="width: 100%"
              v-model="ruleForm.projectId"
              remote
              type="text"
              :remote-method="getProjectNameList"
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
            >
              <el-option
                v-for="item in projectNameList"
                :key="item.projectId"
                :label="item.projectName"
                :value="item.projectId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择企业" prop="enterpriseId">
            <el-select
              size="small"
              style="width: 100%"
              v-model="ruleForm.enterpriseId"
              filterable
              clearable
            >
              <el-option
                v-for="item in enterpriseList"
                :key="item.enterpriseId"
                :label="item.enterpriseName"
                :value="item.enterpriseId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button class="bgc-bv" @click="doSave('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ArchivesOfEachIssue",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      title: "",
      phaesName: "",
      projectName: "",
      enterpriseName: this.$route.query.enterpriseName, //题库名称
      ruleForm: {
        phaesName: "",
        enterpriseId: "",
        projectId: "",
        compNo: "",
        compId: "",
        date: [],
      },
      projectNameList: [],
      enterpriseList: [],
      rules: {
        phaesName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
        projectId: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        enterpriseId: [
          { required: true, message: "请选择企业名称", trigger: "chaneg" },
        ],
      },
    };
  },
  created() {
    console.log(this.$route.query.enterpriseName);
    this.getenterpriseList();
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.enterpriseName) {
        params.enterpriseName = this.enterpriseName;
      }
      if (this.enterpriseId) {
        params.enterpriseId = this.enterpriseId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.phaesName) {
        params.phaesName = this.phaesName;
      }
      this.doFetch({
        url: "/eighteenEnterprise/enterprisePhase/page",
        params,
        pageNum,
      });
    },
    /* 新增 */
    handlAdd() {
      this.dialogVisible = true;
       this.title = "新增";
      this.ruleForm.enterpriseId = this.$route.query.enterpriseId
     
    },
    doSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let parmar = {
            phaesName: this.ruleForm.phaesName,
            enterpriseId: this.ruleForm.enterpriseId,
            projectId: this.ruleForm.projectId,
            selectStartDate: this.ruleForm.date[0],
            selectEndDate: this.ruleForm.date[1],
          };
          if (this.ruleForm.stu == "edit") {
            parmar.phaseId = this.ruleForm.phaseId;
          }
          this.$post(
            this.ruleForm.stu == "edit"
              ? "/eighteenEnterprise/enterprisePhase/modify"
              : "/eighteenEnterprise/enterprisePhase/insert",
            parmar
          )
            .then((ret) => {
              this.dialogVisible = false;
              this.getData();
            })
            .catch((err) => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm = {};
    },
    /* 编辑 */
    handlEdit(row, stu) {
      ;
      this.dialogVisible = true;
      this.title = "修改";
      this.ruleForm = {
        phaesName: row.phaesName,
        projectId: row.projectId,
        enterpriseName: row.enterpriseName,
        enterpriseId: row.enterpriseId,
        date: [
          row.selectStartDate.replaceAll("/", "-"),
          row.selectEndDate.replaceAll("/", "-"),
        ],
        phaseId: row.phaseId,
        stu,
      };
      this.getCompany(row.projectId);
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/biz/project/query", { projectId: id }).then((res) => {
        this.projectNameList = [res.data];
      });
    },
    /* 档案目录 */
    handlCatalogueOfArchives(row) {
      this.$router.push({
        path: "/web/CatalogueOfArchives",
        query: {
          payload: JSON.stringify(row),
        },
      });
    },
    /* 删除 */
    handlDelete(phaseId) {
      this.doDel({
        url: "/eighteenEnterprise/enterprisePhase/delete",
        msg: "你确定要删除该企业该期档案吗？",
        ps: {
          type: "post",
          data: { phaseId },
        },
      });
    },
    getProjectNameList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/project/queryProjectNameList", { projectName: query })
          .then((res) => {
            if (res.status == 0) {
              this.projectNameList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.projectNameList = [];
      }
    },
    getenterpriseList() {
      this.$post("/eighteenEnterprise/enterprise/enterpriseList")
        .then((ret) => {
          console.log(ret);
          if (ret.status == 0) {
            this.enterpriseList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
